import React from 'react';
import { Scheduler } from '@aldabil/react-scheduler';
import { Box } from '@mui/material';
import SchedulerService from 'services/http/schedulerService';
import EventPreview from '../EventPreview';
import SchedulerDialog from '../SchedulerDialog';
import { useStyles } from '../styles';
import { CalendarComponentProps } from './interface';
import { CustomProcessedEvent, StreamingProviders } from '../interface';
import { useGameCast } from 'shared/hooks/admin/useGameCast';
import { usePureweb } from 'shared/hooks/admin/usePureweb';

export const Calendar = ({
  currentEvents,
  currentGroup,
  handleConfirm,
  eventId,
}: CalendarComponentProps) => {
  const classes = useStyles();

  const { getCurrentGroupCalendar } = useGameCast();
  const { getPurewebCalendar } = usePureweb();

  return (
    <Box sx={classes.scheduler}>
      <Scheduler
        hourFormat="24"
        day={{ startHour: 0, endHour: 23, step: 60 }}
        month={{
          startHour: 0,
          endHour: 23,
          weekStartOn: 1,
          weekDays: [0, 1, 2, 3, 4, 5, 6],
        }}
        week={{
          startHour: 0,
          endHour: 23,
          weekStartOn: 1,
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          step: 60,
        }}
        draggable={false}
        customEditor={(state) => (
          <SchedulerDialog scheduler={state} handleConfirm={handleConfirm} />
        )}
        events={currentEvents.map((item) => ({
          ...item,
          start: new Date(item.start),
          end: new Date(item.end),
        }))}
        viewerExtraComponent={(fields, event) => {
          return <EventPreview state={event} />;
        }}
        onDelete={async (startTime) => {
          const currentEvent = currentEvents.find(
            (e) => new Date(e.start).getTime() === startTime
          ) as CustomProcessedEvent | undefined;

          if (!currentEvent) {
            return;
          }

          if (currentEvent.streamingProvider === StreamingProviders.GameCast) {
            if (!currentGroup) {
              return;
            }

            await SchedulerService.deleteEventGameCastSchedulerDate(
              currentGroup.eventId,
              startTime as number
            );

            getCurrentGroupCalendar(currentGroup.eventId);

            return startTime;
          }

          if (
            currentEvent.streamingProvider === StreamingProviders.Pureweb &&
            eventId
          ) {
            await SchedulerService.deleteEvenPurewebSchedulerDate(
              eventId,
              startTime as number
            );

            await getPurewebCalendar(eventId);

            return startTime;
          }
        }}
      />
    </Box>
  );
};

export default Calendar;
