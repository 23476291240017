import { useState, useMemo } from 'react';
import { activeRegionsSelector } from 'redux/slices/admin/purewebSlice';
import SchedulerService, {
  IPurewebTargetRegion,
} from 'services/http/schedulerService';
import { usePureweb } from 'shared/hooks/admin/usePureweb';
import { useAppSelector } from 'shared/hooks/useStore';

export const usePurewebRegions = (
  calendarInitialValues: {
    [key: string]: number;
  } | null
) => {
  const mergedCalendarRegions = useAppSelector(activeRegionsSelector);
  const { getPurewebCalendar } = usePureweb();

  const [checkboxesState, setCheckboxesState] = useState<{
    [key: string]: boolean;
  }>(
    mergedCalendarRegions.reduce((acc: { [key: string]: boolean }, region) => {
      acc[region.code] = false;
      return acc;
    }, {})
  );

  const currentActiveRegions = useMemo(() => {
    const entries = Object.entries(checkboxesState);
    const activeRegions = entries.filter((val) => val[1]);

    const getFullName = (code: string) =>
      mergedCalendarRegions.find((region) => region.code === code)?.full_name ||
      '';

    return activeRegions.map((region) => ({
      code: region[0],
      fullName: getFullName(region[0]),
    }));
  }, [checkboxesState]);

  const purewebInitialValues = useMemo(() => {
    const values = mergedCalendarRegions.reduce(
      (acc: { [key: string]: number }, region) => {
        acc[region.code] = 0;

        return acc;
      },
      {}
    );

    if (!calendarInitialValues) {
      return values;
    }

    return { ...values, ...calendarInitialValues };
  }, [calendarInitialValues]);

  const handleScheduleRegions = async ({
    eventId,
    end,
    modelId,
    newStartTime,
    start,
    targetRegions,
  }: {
    eventId: string;
    end: string | Date;
    modelId: string;
    newStartTime: string | Date | null;
    start: string | Date;
    targetRegions: IPurewebTargetRegion[];
  }) => {
    await SchedulerService.setEventPurewebSchedulerDate(eventId as string, {
      endTime: (end as Date).getTime(),
      modelId: modelId as string,
      newStartTime: (newStartTime as Date)
        ? new Date(newStartTime as Date).getTime()
        : null,
      startTime: (start as Date).getTime(),
      targetRegions,
    });

    await getPurewebCalendar(eventId);
  };

  return {
    setCheckboxesState,
    handleScheduleRegions,
    currentActiveRegions,
    purewebInitialValues,
    checkboxesState,
  };
};
