import { TabContext, TabPanel } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import { Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
// =============================================================================
import { selectEvents, setSendingUpdateForm } from 'redux/slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { EventImagesContainer } from './EventImagesContainer';
import { EventImagesProps, FormData, FormValues } from './types';
import { validationSchema } from './validationSchema';
import { http } from 'services/http';

export const initialStateImages = {
  agendaImage: null,
  logoImage: null,
  bgScreenImage: null,
  customEventLogo: null,
};

export const HandleFormikErrors = ({
  setActualTab,
  actualTab,
}: {
  setActualTab: React.Dispatch<any>;
  actualTab: number;
}) => {
  const { isValid, errors } = useFormikContext();

  useEffect(() => {
    if (!isValid && actualTab > 1) {
      setActualTab('1');
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export const GenericAccessLinkForm = ({
  onSubmitCallback,
  innerRef,
}: {
  onSubmitCallback: (arg: string) => void;
  innerRef: React.MutableRefObject<FormikValues>;
}) => {
  const { event } = useAppSelector(selectEvents);
  const [filesToSubmit, setFilesToSubmit] =
    useState<EventImagesProps>(initialStateImages);
  const [filesUrl, setFilesUrl] =
    useState<EventImagesProps>(initialStateImages);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [actualTab, setActualTab] = useState('1');

  const handleSubmit = async (
    formValues: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    dispatch(setSendingUpdateForm(true));

    const formData: FormData = { expiresIn: '48h' };
    submitForm(formData, setSubmitting);
  };

  function submitForm(
    formData: FormData,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    http.events
      .getGenericAccessLink({
        eventId: event.id as string,
        expiresIn: formData.expiresIn as string,
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        // eslint-disable-next-line react/prop-types
        onSubmitCallback(res.link as string);

        // enqueueSnackbar(res.message, { variant: 'success' });
        enqueueSnackbar('The link has been copied to the clipboard!', {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
        dispatch(setSendingUpdateForm(false));
      });
  }

  const formikValues = {
    initialValues: {
      expiresIn: '48h',
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting);
    },
  };

  return (
    <>
      <Formik
        initialValues={formikValues.initialValues}
        validationSchema={formikValues.validationSchema}
        onSubmit={formikValues.onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        innerRef={innerRef as any}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <HandleFormikErrors
              setActualTab={setActualTab}
              actualTab={parseInt(actualTab)}
            />
            <>
              <TabContext value={actualTab}>
                <TabPanel value="1">
                  <Typography variant="h5">
                    Please enter the expiration time limit
                  </Typography>
                  <Typography variant="body1">Examples:</Typography>

                  <Typography variant="body2">
                    <i>3 minutes = 3m</i>
                  </Typography>

                  <Typography variant="body2">
                    <i>48 hours = 48h</i>
                  </Typography>

                  <Typography variant="body2">
                    <i>1 day = 1d</i>
                  </Typography>

                  <Typography variant="body2">
                    <i>2 weeks = 2w</i>
                  </Typography>

                  <TextField
                    fullWidth
                    margin="normal"
                    id="expiresIn"
                    name="expiresIn"
                    label="Time to expiration"
                    value={formik.values.expiresIn}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.expiresIn &&
                      Boolean(formik.errors.expiresIn)
                    }
                    helperText={
                      formik.touched.expiresIn && formik.errors.expiresIn
                    }
                  />
                </TabPanel>

                <TabPanel value="2">
                  <EventImagesContainer
                    filesToSubmit={filesToSubmit}
                    setFilesToSubmit={setFilesToSubmit}
                    setImagesPreview={setFilesUrl}
                    imagesPreview={filesUrl}
                  />
                </TabPanel>
              </TabContext>
            </>
          </form>
        )}
      </Formik>
    </>
  );
};
