import { REGIONS } from 'pages/admin/GameCastConfigurePage/SchedulerDialog/constants';
import { Event } from '../../shared/types/models/event';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';

export interface IInitialEventsSliceState {
  isEventsLoading: boolean;
  events: Event[];
  isEventLoading: boolean;
  event: Event;
  allEvents: Event[];
  utils: {
    isSendingCreateForm: false;
    isSendingUpdateForm: false;
  };
}

export interface IInitialGenericAccessLinkSliceState {
  isEventsLoading: boolean;
  events: Event[];
  isEventLoading: boolean;
  event: Event;
  allEvents: Event[];
  utils: {
    isSendingCreateForm: false;
    isSendingUpdateForm: false;
  };
}

export interface GameCastConfig {
  eventId: string;
  Arn: string;
  Description: string;
  DefaultApplication: {
    Id: string;
  };
  DesiredCapacity: number;
  StreamClass: string;
  ActiveCapacity: number;
  IdleCapacity: number;
  Id: string;
  Status: string;
  LastUpdatedAt: string;
  CreatedAt: string;
  region: string;
  eventName?: string;
}

export interface StreamGroupConfig {
  eventId: string;
  region: string;
  streamGroupId: string;
}

export interface GameCastState {
  gameCastGroups: GameCastConfig[];
  isLoading: boolean;
  currentGameCastGroup: GameCastConfig | null;
  openConfigurationModal: boolean;
  isCalendarLoading: boolean;
  currentCalendarItems: ProcessedEvent[];
  currentLogs: IStreamGroupLog[];
  loadingLogs: boolean;
}

export interface PurewebState {
  isLoading: boolean;
  currentPurewebModel: IPurewebModel[] | null;
  isLoadingPurewebCalendar: boolean;
  purewebCalendarEvents: ProcessedEvent[];
  purewebLogs: IStreamGroupLog[];
  loadingPurewebLogs: boolean;
  activeRegions: typeof REGIONS;
}

export interface IStreamGroupLog {
  eventId: string;
  timestamps: number;
  actionType: StreamGroupLogsActions;
  capacity: number;
  startTime?: number;
  endTime?: number;
  isLambdaAction: boolean;
  participantId?: string;
  region: string;
  id?: string;
  streamGroupId: string;
}

export interface IPurewebModel {
  schedule: [];
  min: number;
  max: number;
  desired: number;
  name: string;
  region: string;
}

export enum StreamGroupLogsActions {
  StoppingMachines = 'STOPPING MACHINES',
  Delete = 'DELETE',
  ReserveMachines = 'RESERVE MACHINES',
  Update = 'UPDATE',
  ActivatingMachines = 'ACTIVATING MACHINES',
}
