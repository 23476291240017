import axios from 'axios';
import { useState } from 'react';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { HttpReponseDefault } from 'shared/types/httpGeneric';

interface UploadMediaPreSignedProps {
  preSignedUrl: string;
  imageFile: File;
  contentType: 'image' | 'video/mp4' | '*/*';
}

const useUploadMediaUsingPreSignedUrl = (): [
  ({
    preSignedUrl,
    imageFile,
    contentType,
  }: UploadMediaPreSignedProps) => Promise<HttpReponseDefault>,
  number
] => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const upload = async ({
    preSignedUrl,
    imageFile,
    contentType,
  }: UploadMediaPreSignedProps): Promise<HttpReponseDefault> => {
    try {
      const res = await axios.put(preSignedUrl, imageFile, {
        headers: {
          'Content-Type': contentType,
        },
        onUploadProgress: function (progressEvent) {
          const progress: number = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setUploadProgress(progress);
        },
      });
      return res;
    } catch (error) {
      return errorsApiHandler(error);
    } finally {
      setUploadProgress(0);
    }
  };

  return [upload, uploadProgress];
};

export default useUploadMediaUsingPreSignedUrl;
