import React from 'react';
import { Box, Typography } from '@mui/material';
import { generateLogMessage } from '../LogsPreview/helpers';
import { useStyles } from './styles';
import { IStreamGroupLog } from 'redux/slices/interfaces';

const LogsPreviewItem = ({ log }: { log: IStreamGroupLog }) => {
  const classes = useStyles();

  return (
    <Box sx={classes.contentWrapper} key={log.timestamps}>
      <Typography component="p">{log.participantId || 'Auto'}</Typography>
      <Typography component="p">
        {generateLogMessage({ actionType: log.actionType })}
      </Typography>
      <Typography component="p">
        {log.startTime ? new Date(log.startTime).toLocaleString() : ''}
      </Typography>
      <Typography component="p">
        {log.endTime ? new Date(log.endTime).toLocaleString() : ''}
      </Typography>
      <Typography component="p">{log.capacity}</Typography>
      <Typography component="p">{log.region}</Typography>
      <Typography component="p">
        {new Date(log.timestamps).toLocaleString()}
      </Typography>
    </Box>
  );
};

export default LogsPreviewItem;
