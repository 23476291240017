import React, { useEffect, useState } from 'react';

import { Box, Tab } from '@mui/material';
import { AppDefaultLayout } from 'layouts/AppDefaultLayout';

import { DefaultPageHeader } from 'shared/components/headers/DefaultPageHeader';
import { useStyles } from './styles';
import ConfigurationModal from './ConfigurationModal/ConfigurationModal';
import LoadingProgress from 'components/LoadingProgress/LoadingProgress';
import GroupInfoPreview from './GroupInfoPreview/GroupInfoPreview';
import {
  GameCastTabValues,
  SortingCriteriaTypes,
  SortingOrderTypes,
} from './interface';
import { appPaths } from 'app/router/appPaths';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Calendar from './Calendar/Calendar';
import { GameCastConfig, IStreamGroupLog } from 'redux/slices/interfaces';
import LogsPreview from './LogsPreview/LogsPreview';
import { useGameCastConfigure } from './hooks';
import { sortLogs } from './helpers';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useStore';
import {
  loadingPurewebCalendarSelector,
  loadingPurewebModelSelector,
  purewebLogsSelector,
} from 'redux/slices/admin/purewebSlice';

const GameCastConfigurePage = () => {
  const classes = useStyles();
  const { eventId } = useParams();
  const [currentEventLogs, setCurrentEventLogs] = useState<IStreamGroupLog[]>(
    []
  );
  const purewebLogs = useAppSelector(purewebLogsSelector);

  const {
    currentEvents,
    currentGroup,
    currentLogs,
    isCalendarLoading,
    modalState,
    loadingGroup,
    tabValue,
    isUpdatingCapacity,
    sortingConfig,
    isFromEvent,
    handleCloseModal,
    handleChangeTabValue,
    handleConfirm,
    setSortingConfig,
  } = useGameCastConfigure();

  const loadingPurewebConfig = useAppSelector(loadingPurewebModelSelector);
  const loadingPurewebCalendar = useAppSelector(loadingPurewebCalendarSelector);

  const linkToValue = isFromEvent
    ? `${appPaths.private.event.dashboard}/${eventId}`
    : `${appPaths.calendar.gameCastScheduler}`;

  const linkToTextValue = isFromEvent
    ? 'Back to event'
    : 'Back to Virtual Machines Dashboard';

  useEffect(() => {
    setCurrentEventLogs([...purewebLogs, ...currentLogs]);
  }, [purewebLogs, currentLogs]);

  if (loadingGroup || loadingPurewebConfig) {
    return <LoadingProgress classes={{ spinner: classes.spinner }} />;
  }

  return (
    <AppDefaultLayout>
      <DefaultPageHeader
        titleText="Stream Group Configure"
        linkTo={linkToValue}
        linkToText={linkToTextValue}
      />
      <GroupInfoPreview />
      <ConfigurationModal
        open={modalState}
        handleCloseModal={handleCloseModal}
        currentCapacity={
          isUpdatingCapacity
            ? currentGroup?.DesiredCapacity
            : currentGroup?.ActiveCapacity
        }
        eventId={currentGroup?.eventId as string}
        region={currentGroup?.region as string}
        streamGroupId={currentGroup?.Id as string}
      />
      <TabContext value={tabValue}>
        <Box sx={classes.tabs}>
          <TabList onChange={handleChangeTabValue}>
            <Tab label="Calendar" value={GameCastTabValues.Calendar} />
            <Tab label="Logs" value={GameCastTabValues.Logs} />
          </TabList>
        </Box>
        <TabPanel value={GameCastTabValues.Calendar}>
          {isCalendarLoading || loadingPurewebCalendar ? (
            <LoadingProgress />
          ) : (
            <Calendar
              currentEvents={currentEvents}
              currentGroup={currentGroup as GameCastConfig}
              handleConfirm={handleConfirm}
              eventId={eventId}
            />
          )}
        </TabPanel>
        <TabPanel value={GameCastTabValues.Logs}>
          <LogsPreview
            setSortingConfig={setSortingConfig}
            sortingConfig={sortingConfig}
            eventId={eventId as string}
            logs={sortLogs({
              logs:
                currentEventLogs && currentEventLogs.length
                  ? currentEventLogs.slice()
                  : [],
              criteria: sortingConfig?.criteria || SortingCriteriaTypes.Time,
              order: sortingConfig?.order || SortingOrderTypes.DESC,
            })}
          />
        </TabPanel>
      </TabContext>
    </AppDefaultLayout>
  );
};

export default GameCastConfigurePage;
