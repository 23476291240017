import React from 'react';
import { Typography, Box } from '@mui/material';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import { CustomProcessedEvent } from '../interface';
import { getStreamingProviderFullName } from '../helpers';

const EventPreview = ({
  state,
}: {
  state: ProcessedEvent | CustomProcessedEvent;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography>
        {`${new Date(state.start).toLocaleTimeString('default', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}`}
        -
        {`${new Date(state.end).toLocaleTimeString('default', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}`}
      </Typography>
      <Typography>Capacity: {state.capacity}</Typography>
      <Typography>
        Streaming provider:{' '}
        {getStreamingProviderFullName(state.streamingProvider)}
      </Typography>
    </Box>
  );
};

export default EventPreview;
