import {
  ISortLogsProps,
  SortingCriteriaTypes,
  SortingOrderTypes,
  StreamingProviders,
} from './interface';

export const sortLogs = ({ criteria, order, logs }: ISortLogsProps) => {
  const { Capacity, EndTime, StartTime, Time } = SortingCriteriaTypes;
  const { DESC } = SortingOrderTypes;

  const sorting = {
    [Capacity]: logs
      .slice()
      .sort((a, b) =>
        order === DESC ? b.capacity - a.capacity : a.capacity - b.capacity
      ),
    [EndTime]: logs
      .slice()
      .sort((a, b) =>
        order === DESC
          ? (b.endTime || 0) - (a.endTime || 0)
          : (a.endTime || 0) - (b.endTime || 0)
      ),
    [StartTime]: logs
      .slice()
      .sort((a, b) =>
        order === DESC
          ? (b.startTime || 0) - (a.startTime || 0)
          : (a.startTime || 0) - (b.startTime || 0)
      ),
    [Time]: logs
      .slice()
      .sort((a, b) =>
        order === DESC
          ? b.timestamps - a.timestamps
          : a.timestamps - b.timestamps
      ),
  };

  return sorting[criteria];
};

export const getStreamingProviderFullName = (
  streamingProvider: StreamingProviders
) => {
  const providersNames = {
    [StreamingProviders.GameCast]: 'AWS cloud stream',
    [StreamingProviders.Pureweb]: 'Pureweb Pixel Stream',
  };

  return providersNames[streamingProvider] || '';
};
