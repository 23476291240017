import { Box, Grid, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { SyntheticEvent, useRef, useState } from 'react';
import { selectRooms, setActualSpot } from 'redux/slices/roomsSlice';
import { MediaTypeIcon } from 'shared/components/MediaTypeIcon';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import { ModalHandles } from 'shared/types/modal';
import { PointsOfInterest } from './PointsOfInterest';
import { RoomSpotCard } from './RoomSpotCard';
import { UploadIconMapWrapper } from './styled';
import { a11yProps, TabPanel } from './TabPanel';
import UploadBriefCaseModal from './UploadMediaModal/UploadBriefCaseModal';
import UploadImageModal from './UploadMediaModal/UploadImageModal';
import UploadUnrealCodeModal from './UploadMediaModal/UploadUnrealCodeModal';
import UploadVideoModal from './UploadMediaModal/UploadVideoModal';
import { ExportAndImportSpots } from './ExportImportSpots/ExportAndImportSpots';
import { ExportAndImportPOIs } from './ExportImportPOIs/ExportAndImportPOIs';
import { SpotMediaType } from '../../../shared/types/rooms';

const MediaContainer = () => {
  // useEffect(() => { }, []);

  const { roomSpots, roomMap } = useAppSelector(selectRooms);

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch = useAppDispatch();

  const uploadImageModalRef = useRef<ModalHandles>(null);
  const uploadVideoModalRef = useRef<ModalHandles>(null);
  const uploadUnrealCodeModalRef = useRef<ModalHandles>(null);
  const uploadBriefCaseModalRef = useRef<ModalHandles>(null);
  const showBaseMap = false;

  const openImageModal = () => {
    uploadImageModalRef.current?.handleOpen();
  };

  const openVideoModal = () => {
    uploadVideoModalRef.current?.handleOpen();
  };

  const openBriefCaseModal = () => {
    uploadBriefCaseModalRef.current?.handleOpen();
  };

  const openUnrealCodeModal = () => {
    uploadUnrealCodeModalRef.current?.handleOpen();
  };

  const handleMediaOpenModal = (item: any) => {
    dispatch(setActualSpot(item));

    switch (item.mediaType) {
      case SpotMediaType.Video:
        openVideoModal();
        break;

      case SpotMediaType.Three_D:
        openBriefCaseModal();
        break;

      case SpotMediaType.Image:
        openImageModal();
        break;

      case SpotMediaType.UnrealCode:
        openUnrealCodeModal();
        break;

      case SpotMediaType.WebBrowser:
        openUnrealCodeModal();
        break;

      case SpotMediaType.Audio:
        openBriefCaseModal();
        break;

      default:
        openBriefCaseModal();
        break;
    }
  };

  return (
    <>
      {roomMap.imageBaseMap && (
        <>
          <UploadImageModal ref={uploadImageModalRef} />
          <UploadVideoModal ref={uploadVideoModalRef} />
          <UploadBriefCaseModal ref={uploadBriefCaseModalRef} />
          <UploadUnrealCodeModal ref={uploadUnrealCodeModalRef} />

          {showBaseMap && (
            <>
              <h3>Room base map</h3>
              <Box>
                <div style={{ position: 'relative', width: '800px' }}>
                  <img
                    src={roomMap.imageBaseMap}
                    alt={`room-base-map`}
                    style={{ width: '100%', maxWidth: '800px' }}
                  />
                  {roomSpots.length > 0 &&
                    roomSpots.map((item: any) => (
                      <UploadIconMapWrapper
                        key={item.spotModelId}
                        style={{
                          position: 'absolute',
                          top: `${item.coordTop}px`,
                          left: `${item.coordLeft}px`,
                        }}
                        onClick={() => handleMediaOpenModal(item)}
                      >
                        <Tooltip title={item.displayName as string}>
                          <Stack justifyContent="center" alignItems="center">
                            <MediaTypeIcon media={item.mediaType as string} />
                          </Stack>
                        </Tooltip>
                      </UploadIconMapWrapper>
                    ))}
                </div>
              </Box>
            </>
          )}

          <Box sx={{ width: '100%', marginTop: '32px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Grid container direction="row">
                <Grid xs={6} item>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                  >
                    <Tab label="Upload room media" {...a11yProps(0)} />
                    <Tab label="Points of Interest" {...a11yProps(1)} />
                  </Tabs>
                </Grid>

                {value == 0 && <ExportAndImportSpots />}

                {value == 1 && <ExportAndImportPOIs />}
              </Grid>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid container columnGap={3} marginBottom={2}>
                {roomSpots.length > 0 &&
                  roomSpots.map((item: any) => (
                    <RoomSpotCard
                      key={item.id}
                      item={item}
                      onClick={() => handleMediaOpenModal(item)}
                    />
                  ))}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PointsOfInterest />
            </TabPanel>
          </Box>
        </>
      )}
    </>
  );
};

export default MediaContainer;
