import axios from 'axios';
import { apiUrl } from 'configs/apiConfigs';
import errorsApiHandler from 'services/errors/httpErrors/errorsApiHandler';
import { HttpReponseDefault } from 'shared/types/httpGeneric';
import { v4 as uuidv4 } from 'uuid';

export const getPreSignedUrl = async ({
  filename,
  foldername,
  userToken,
}: {
  filename: string;
  foldername: 'images' | 'videos' | 'briefCases' | '3D';
  userToken: string;
}): Promise<HttpReponseDefault & { url?: string }> => {
  const generatedId = uuidv4();

  try {
    // default url format: `${apiUrl}/s3url?filename=images/1223e521860386252aabce4e32add7aef6-mainlobby.png`
    const res = await axios.get(
      `${apiUrl}/s3url?filename=${foldername}/${generatedId}-${filename}`,
      {
        headers: {
          Authorization: `${userToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return res.data;
  } catch (error) {
    return errorsApiHandler(error);
  }
};
