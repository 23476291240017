import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useAccess from 'shared/hooks/useAccess';
import useUploadMediaUsingPreSignedUrl from 'shared/hooks/useUploadMediaUsingPreSignedUrl';
import formatPresignedUrlToImageUrl from 'shared/utils/formatPresignedUrlToImageUrl';
import { getPreSignedUrl } from 'shared/utils/getPreSignedUrl';

interface UploadAccessProccessProps {
  asset: File;
  foldername?: 'images' | 'videos' | 'briefCases' | '3D';
  contentType?: 'image' | 'video/mp4' | '*/*';
}

export const useUploadAssetProccess = () => {
  const [isSendingFile, setSendingFile] = useState(false);
  const [uploadStep, setUploadStep] = useState(0); // step index to manage CustomizedSteppers component
  const [errorStep, setErrorStep] = useState<number | null>(null);

  const { getConnectToken } = useAccess();
  const { enqueueSnackbar } = useSnackbar();
  const [upload, uploadProgress] = useUploadMediaUsingPreSignedUrl();

  useEffect(() => {
    return () => {
      handleFinishUpload();
    };
  }, []);

  const handleFinishUpload = () => {
    setSendingFile(false);
    setUploadStep(0);
    setErrorStep(null);
  };

  const uploadAssetProccess = async ({
    asset,
    foldername = '3D',
    contentType = '*/*',
  }: UploadAccessProccessProps) => {
    if (!(asset instanceof File)) return false;

    setSendingFile(true);

    const preSignedUrl = await getPreSignedUrl({
      filename: asset.name,
      foldername,
      userToken: getConnectToken() as string,
    });

    if (preSignedUrl?.error) {
      enqueueSnackbar(preSignedUrl?.message, { variant: 'error' });
      setErrorStep(0);
      return;
    }

    setUploadStep(1);

    const uploadMedia = await upload({
      preSignedUrl: preSignedUrl.url as string,
      imageFile: asset,
      contentType,
    });

    if (uploadMedia?.error) {
      enqueueSnackbar(preSignedUrl?.message, { variant: 'error' });
      setErrorStep(1);
      return;
    }

    setUploadStep(2);

    const assetUrl = formatPresignedUrlToImageUrl(preSignedUrl.url as string);

    handleFinishUpload();

    return assetUrl;
  };

  return {
    // states
    isSendingFile,
    uploadStep,
    errorStep,
    uploadProgress, // from useUploadMediaUsingPreSignedUrl hook

    // functions
    uploadAssetProccess,
  };
};
