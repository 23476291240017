import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
} from '@mui/material';
import BoxDialogModal, {
  BoxDialogModalHandles,
} from 'shared/components/modals/BoxDialogModal';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useStore';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  selectRooms,
  setActualSpot,
  setSendingMediaForm,
} from 'redux/slices/roomsSlice';
import { ModalHandles } from 'shared/types/modal';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Input, UploadIconWrapper } from './styled';
import { http } from 'services/http';
import { useSnackbar } from 'notistack';
import { selectUser } from 'redux/slices/userSlice';
import useAccess from 'shared/hooks/useAccess';
import { useLoadRoomDetails } from 'shared/hooks/useLoadRoomDetails';
import { SpotDataProps } from './types';
import CustomizedSteppers from 'shared/components/steppers/CustomizedSteppers';
import { getPreSignedUrl } from 'shared/utils/getPreSignedUrl';
import useUploadMediaUsingPreSignedUrl from 'shared/hooks/useUploadMediaUsingPreSignedUrl';
import formatPresignedUrlToImageUrl from 'shared/utils/formatPresignedUrlToImageUrl';
import { ResetSpot } from './ResetSpot';
import { renameFileToUploadPattern } from 'shared/utils/renameFileToUploadPattern';

const UploadImageModal: ForwardRefRenderFunction<ModalHandles> = (
  props,
  ref
) => {
  const { user } = useAppSelector(selectUser);
  const { actualSpot } = useAppSelector(selectRooms);
  const { getConnectToken } = useAccess();
  const { enqueueSnackbar } = useSnackbar();

  const {
    utils: { isSendingMediaForm },
  } = useAppSelector(selectRooms);
  const { loadRoomDetails } = useLoadRoomDetails();

  const dispatch = useAppDispatch();
  const [upload, uploadProgress] = useUploadMediaUsingPreSignedUrl();

  const [isSendingImage, setSendingImage] = useState(false);
  const [spotImage, setSpotImage] = useState<string | ArrayBuffer | null>(null);
  const [fileToSubmit, setFileToSubmit] = useState<File | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [uploadStep, setUploadStep] = useState(0); // step index to manage CustomizedSteppers component
  const [errorStep, setErrorStep] = useState<number | null>(null);

  useEffect(() => {
    if (actualSpot?.isActive) setChecked(actualSpot.isActive);
  }, [actualSpot.isActive]);

  const boxDialogModalRef = useRef<BoxDialogModalHandles>(null);

  const handleOpen = () => {
    boxDialogModalRef.current?.handleOpen();
  };

  const handleClose = () => {
    boxDialogModalRef.current?.handleClose();
  };

  const handleCloseDialog = () => {
    setSendingImage(false);
    setSendingMediaForm(false);
    dispatch(setActualSpot({}));
    setSpotImage(null);
    setUploadStep(0);
    setErrorStep(null);
    setChecked(false);
  };

  const handleSubmitForm = async () => {
    dispatch(setSendingMediaForm(true));

    const spotID = actualSpot.id;

    const data: SpotDataProps = {
      user: user?.data.email as string,
      isActive: checked,
      isDefault: false,
    };

    if (fileToSubmit) {
      setSendingImage(true);

      const preSignedUrl = await getPreSignedUrl({
        filename: fileToSubmit.name,
        foldername: 'images',
        userToken: getConnectToken() as string,
      });

      if (preSignedUrl?.error) {
        enqueueSnackbar(preSignedUrl?.message, { variant: 'error' });
        setErrorStep(0);
        return;
      }

      setUploadStep(1);

      const uploadMedia = await upload({
        preSignedUrl: preSignedUrl.url as string,
        imageFile: fileToSubmit,
        contentType: 'image',
      });

      if (uploadMedia?.error) {
        enqueueSnackbar(preSignedUrl?.message, { variant: 'error' });
        setErrorStep(1);
        return;
      }

      setUploadStep(2);

      const imageUrl = formatPresignedUrlToImageUrl(
        preSignedUrl?.url as string
      );

      data.image = imageUrl;
    }

    http.spots
      .updateFromFrontend({
        userToken: getConnectToken() as string,
        spotID,
        data,
      })
      .then((res: any) => {
        dispatch(setSendingMediaForm(false));
        if (res.error) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        setUploadStep(3);
        enqueueSnackbar(res.message, { variant: 'success' });
        loadRoomDetails();
        handleClose();
      })
      .catch((error) => {
        dispatch(setSendingMediaForm(false));
        setErrorStep(2);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleUploadImageInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    let file = event.target.files[0];
    const reader = new FileReader();

    file = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setSpotImage(reader.result);
      setFileToSubmit(file);
    };

    reader.readAsDataURL(file);

    event.target.value = '';
  };

  useImperativeHandle(ref, () => {
    return { handleOpen, handleClose };
  });

  return (
    <BoxDialogModal
      ref={boxDialogModalRef}
      title={
        'Update spot - ' +
        actualSpot?.displayName +
        ' [' +
        actualSpot?.mediaType +
        ']'
      }
      submitButton={
        <Button
          autoFocus
          onClick={handleSubmitForm}
          variant="contained"
          disabled={isSendingMediaForm}
        >
          {!isSendingMediaForm ? 'Save' : 'Saving...'}
        </Button>
      }
      onCloseDialog={handleCloseDialog}
    >
      {!isSendingImage && (
        <Stack spacing={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Active"
            />
          </FormGroup>

          <label htmlFor="icon-button-file">
            <Input
              accept=".jpg, .pjpg, .jpeg, .png, .gif"
              id="icon-button-file"
              type="file"
              onChange={handleUploadImageInput}
            />
            <IconButton
              style={{ width: '320px' }}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <UploadIconWrapper>
                <UploadFileIcon />
              </UploadIconWrapper>

              {spotImage ? (
                <img
                  src={`${spotImage}`}
                  alt={`${actualSpot.displayName}`}
                  style={{ width: '100%' }}
                />
              ) : (
                <img
                  src={`${actualSpot.image}`}
                  alt={`${actualSpot.displayName}`}
                  style={{ width: '100%' }}
                />
              )}
            </IconButton>
          </label>

          {actualSpot.isDefault === false && (
            <ResetSpot
              actualSpot={actualSpot}
              onSuccess={() => handleClose()}
            />
          )}
        </Stack>
      )}

      {isSendingImage && (
        <CustomizedSteppers
          activeStep={uploadStep}
          errorStep={errorStep}
          uploadProgress={uploadProgress}
        />
      )}
    </BoxDialogModal>
  );
};

export default forwardRef(UploadImageModal);
