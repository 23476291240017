import { useSnackbar } from 'notistack';
import useAsyncEffect from 'use-async-effect';

import { useAppDispatch, useAppSelector } from '../useStore';

import {
  currentPurewebModelSelector,
  setActiveRegions,
  setCurrentPurewebModel,
  setIsLoading,
  setIsLoadingPurewebCalendar,
  setLoadingPurewebLogs,
  setPurewebCalendarEvents,
  setPurewebLogs,
} from 'redux/slices/admin/purewebSlice';
import EventsService from 'services/http/eventsService';

import {
  CustomProcessedEvent,
  StreamingProviders,
} from 'pages/admin/GameCastConfigurePage/interface';
import SchedulerService from 'services/http/schedulerService';
import { useParams } from 'react-router-dom';
import { REGIONS } from 'pages/admin/GameCastConfigurePage/SchedulerDialog/constants';

export const usePureweb = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { eventId } = useParams();

  const dispatch = useAppDispatch();
  const currentModel = useAppSelector(currentPurewebModelSelector);

  const loadCurrentPurewebModel = async (purewebModelId: string) => {
    dispatch(setIsLoading(true));
    const models = await EventsService.getPurewebModel(purewebModelId);

    if (!models) {
      dispatch(setIsLoading(false));
      enqueueSnackbar('Error while loading Pureweb models');
      return;
    }

    const availableRegions = models.map((model) => model.region);

    if (!availableRegions) {
      return;
    }

    const regions = availableRegions.reduce((acc: typeof REGIONS, region) => {
      const existingRegion = REGIONS.find((r) => r.code === region);

      if (!existingRegion) {
        return acc;
      }

      return [...acc, existingRegion];
    }, []);

    dispatch(setActiveRegions(regions));
    dispatch(setCurrentPurewebModel(models));
    dispatch(setIsLoading(false));
  };

  const emptyCurrentPurewebModel = () => {
    dispatch(setCurrentPurewebModel(null));
  };

  const getPurewebCalendar = async (eventId: string) => {
    dispatch(setIsLoadingPurewebCalendar(true));
    const calendar = await SchedulerService.getEventPurewebSchedulerDate(
      eventId
    );

    if (!calendar.length) {
      dispatch(setPurewebCalendarEvents([]));
      dispatch(setIsLoadingPurewebCalendar(false));
      return;
    }

    const parsedCalendar = calendar.map<CustomProcessedEvent>((event) => {
      return {
        end: new Date(event.endTime).toString(),
        start: new Date(event.startTime).toString(),
        title: '',
        event_id: event.startTime,
        targetRegions: event.targetRegions,
        streamingProvider: StreamingProviders.Pureweb,
      };
    });

    dispatch(setPurewebCalendarEvents(parsedCalendar));
    dispatch(setIsLoadingPurewebCalendar(false));
  };

  useAsyncEffect(async () => {
    if (!currentModel || !eventId) {
      return;
    }
    dispatch(setLoadingPurewebLogs(true));
    const purewebLogs = await EventsService.getPurewebLogs(eventId);

    dispatch(setPurewebLogs(purewebLogs));
    dispatch(setLoadingPurewebLogs(false));
  }, [currentModel, eventId]);

  const emptyCurrentModelLogs = () => {
    dispatch(setPurewebLogs([]));
  };

  const emptyPurewebCalendar = () => {
    dispatch(setPurewebCalendarEvents([]));
  };

  return {
    loadCurrentPurewebModel,
    emptyCurrentPurewebModel,
    getPurewebCalendar,
    emptyCurrentModelLogs,
    emptyPurewebCalendar,
  };
};
