import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import { ChangeEvent, useState } from 'react';
import { BootstrapDialogTitle } from 'shared/components/modals/BoxDialogModal/BootstrapDialogTitle';
import { BoxDialog } from 'shared/components/modals/BoxDialogModal/styled';
import CustomizedSteppers from 'shared/components/steppers/CustomizedSteppers';
import { PointOfInterest } from 'shared/types/models/pointOfInterest';
import { getPoiAcceptCriteriaFile } from 'shared/utils/getAcceptCriteriaFile';
import { renameFileToUploadPattern } from 'shared/utils/renameFileToUploadPattern';
import { useUploadAssetProccess } from '../../../../shared/hooks/useUploadAssetProccess';
import { validationSchema } from './validationSchema';
import { MEDIA_TYPE_DATA } from '../constants';
import { PoiMediaType } from '../../../../shared/types/utils';

interface AddProps {
  open: boolean;
  userEmail: string;
  roomId: string;
  onSubmit: (values: any, setSubmitting: any) => void;
  handleClose: () => void;
}

interface Values {
  displayName: string;
  description: string;
  mediaType: PoiMediaType | string;
  posX: number;
  posY: number;
  posZ: number;
  rotationY: number;
  rotationZ: number;
  rotationX: number;
  scale: number;
  isInteractive: boolean;
  browserId: string;
  browserUrl: string;
}

interface Assets {
  asset?: File;
  description3dImage?: File;
  displayImage?: File;
}

export default function AddPointsOfInterestModal({
  open,
  userEmail,
  roomId,
  handleClose,
  onSubmit,
}: AddProps) {
  const [assets, setAssets] = useState<Assets>();
  const [assetUrl, setAssetUrl] = useState<string>();
  const [showMaximimumUploadSizeError, setShowMaximimumUploadSizeError] =
    useState(false);
  const {
    uploadAssetProccess,
    isSendingFile,
    uploadStep,
    errorStep,
    uploadProgress,
  } = useUploadAssetProccess();

  const handleSubmit = async (values: Values, setSubmitting: any) => {
    setSubmitting(true);

    const position = [values.posX, values.posY, values.posZ];
    const rotation = [values.rotationX, values.rotationY, values.rotationZ];

    const payload: PointOfInterest = {
      displayName: values.displayName,
      description: values.description,
      mediaType: values.mediaType,
      position: position,
      rotation: rotation,
      scale: values.scale,
      user: userEmail,
      room: roomId,
      isInteractive3D: values.mediaType === PoiMediaType.ThreeDObjectView,
      asset: assetUrl,
      browserId: values.browserId,
      browserUrl: values.browserUrl,
    };

    if (assets) {
      for (const [key, value] of Object.entries(assets)) {
        if (!value || !key) return;
        const assetProccessed = await uploadAssetProccess({
          asset: value as File,
          foldername: 'briefCases',
        });

        if (typeof assetProccessed === 'string') {
          payload[key] = assetProccessed; // means it's the url returned after proccess
        } else {
          setSubmitting(false);
        }
      }
    }

    handleClose();

    onSubmit(payload, setSubmitting);
  };

  const handleUploadInput = (
    event: ChangeEvent<HTMLInputElement>,
    tag: string
  ) => {
    if (!event.target.files) return;
    setShowMaximimumUploadSizeError(false);

    let file = event.target.files[0];
    if (file.size / 1000000 > 1000) {
      setShowMaximimumUploadSizeError(true);
      return;
    }
    const reader = new FileReader();

    file = renameFileToUploadPattern(file);

    reader.onloadend = () => {
      setAssets({ ...assets, [tag]: file });
    };

    reader.readAsDataURL(file);

    event.target.value = '';
  };

  const initialValues: Values = {
    displayName: '',
    mediaType: '',
    description: '',
    posX: 0.0,
    posY: 0.0,
    posZ: 0.0,
    rotationY: 0.0,
    rotationZ: 0.0,
    rotationX: 0.0,
    scale: 1.0,
    isInteractive: false,
    browserId: '',
    browserUrl: '',
  };
  const formikValues = {
    initialValues,
    validationSchema: validationSchema,
  };

  return (
    <BoxDialog
      open={open}
      onClose={() => {
        handleClose();
        setAssets(undefined);
      }}
      aria-labelledby="add-point-dialog-title"
      scroll="body"
    >
      {!isSendingFile && (
        <Formik
          initialValues={formikValues.initialValues}
          validationSchema={formikValues.validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <BootstrapDialogTitle
                id="add-point-dialog-title"
                onClose={() => {
                  handleClose();
                  setAssets(undefined);
                }}
              >
                <Box>Add a Point of Interest</Box>
                <Box>
                  <label htmlFor="icon-upload-display-image">
                    <input
                      id="icon-upload-display-image"
                      type="file"
                      accept={getPoiAcceptCriteriaFile(PoiMediaType.Image)}
                      style={{ display: 'none' }}
                      onChange={(e) => handleUploadInput(e, 'displayImage')}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<FileUploadIcon />}
                      size="small"
                    >
                      Upload thumbnail image
                    </Button>
                  </label>
                </Box>
              </BootstrapDialogTitle>

              <DialogContent dividers style={{ minWidth: '450px' }}>
                <TextField
                  fullWidth
                  size="small"
                  id="displayName"
                  name="displayName"
                  label="Display Name"
                  value={formik.values.displayName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.displayName &&
                    Boolean(formik.errors.displayName)
                  }
                  helperText={
                    formik.touched.displayName && formik.errors.displayName
                  }
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="description"
                  name="description"
                  label="Description"
                  size="small"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={formik.values.mediaType?.startsWith('3d') ? 6 : 12}
                  >
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <InputLabel id="mediaType">Media Type</InputLabel>
                      <Select
                        labelId="mediaType"
                        id="mediaType"
                        name="mediaType"
                        value={formik.values.mediaType}
                        label="Media Type"
                        onChange={(event) => {
                          setAssets(undefined);
                          formik.handleChange(event);
                        }}
                      >
                        {MEDIA_TYPE_DATA.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    {formik.values.mediaType?.startsWith('3d') && (
                      <Stack>
                        <label htmlFor="icon-upload-description-3d">
                          <input
                            id="icon-upload-description-3d"
                            type="file"
                            accept={getPoiAcceptCriteriaFile(
                              PoiMediaType.Image
                            )}
                            style={{ display: 'none' }}
                            onChange={(e) =>
                              handleUploadInput(e, 'description3dImage')
                            }
                          />
                          <Button
                            fullWidth
                            variant="outlined"
                            component="span"
                            startIcon={<FileUploadIcon />}
                            size="small"
                            sx={{ marginTop: 0.7 }}
                          >
                            Upload description image
                          </Button>
                        </label>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                <Stack overflow={`hidden`} mt={2}>
                  <Grid container>
                    <Grid item xs>
                      <h5 style={{ margin: '5px 0 10px 0' }}>Positions</h5>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        marginBottom={2}
                      >
                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            type="number"
                            id="posX"
                            name="posX"
                            label="X"
                            value={formik.values.posX}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.posX && Boolean(formik.errors.posX)
                            }
                            helperText={
                              formik.touched.posX && formik.errors.posX
                            }
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            type="number"
                            id="posY"
                            name="posY"
                            label="Y"
                            value={formik.values.posY}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.posY && Boolean(formik.errors.posY)
                            }
                            helperText={
                              formik.touched.posY && formik.errors.posY
                            }
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            type="number"
                            id="posZ"
                            name="posZ"
                            label="Z"
                            value={formik.values.posZ}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.posZ && Boolean(formik.errors.posZ)
                            }
                            helperText={
                              formik.touched.posZ && formik.errors.posZ
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ margin: '0 10px' }}
                    />
                    <Grid item xs>
                      <h5 style={{ margin: '5px 0 10px 0' }}>Rotation</h5>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        marginBottom={2}
                      >
                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            type="number"
                            id="rotationX"
                            name="rotationX"
                            label="X"
                            value={formik.values.rotationX}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.rotationX &&
                              Boolean(formik.errors.rotationX)
                            }
                            helperText={
                              formik.touched.rotationX &&
                              formik.errors.rotationX
                            }
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            type="number"
                            id="rotationY"
                            name="rotationY"
                            label="Y"
                            value={formik.values.rotationY}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.rotationY &&
                              Boolean(formik.errors.rotationY)
                            }
                            helperText={
                              formik.touched.rotationY &&
                              formik.errors.rotationY
                            }
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            type="number"
                            id="rotationZ"
                            name="rotationZ"
                            label="Z"
                            value={formik.values.rotationZ}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.rotationZ &&
                              Boolean(formik.errors.rotationZ)
                            }
                            helperText={
                              formik.touched.rotationZ &&
                              formik.errors.rotationZ
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>

                <Stack>
                  <h5 style={{ margin: '5px 0 15px 0' }}>Scale</h5>
                  <TextField
                    size="small"
                    fullWidth
                    type="number"
                    id="scale"
                    name="scale"
                    label="Scale"
                    value={formik.values.scale}
                    onChange={(
                      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => {
                      if (e.target.value.indexOf('0') === 0) {
                        e.target.value = e.target.value.substring(1);
                      }
                      formik.handleChange(e);
                    }}
                    error={formik.touched.scale && Boolean(formik.errors.scale)}
                    helperText={formik.touched.scale && formik.errors.scale}
                  />
                </Stack>

                <Stack mt={4} mb={2}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Stack justifyContent="center" height="100%">
                        <label htmlFor="icon-point-button-file">
                          <input
                            id="icon-point-button-file"
                            type="file"
                            disabled={!formik.values.mediaType}
                            accept={getPoiAcceptCriteriaFile(
                              formik.values.mediaType as PoiMediaType
                            )}
                            style={{ display: 'none' }}
                            onChange={(e) => handleUploadInput(e, 'asset')}
                          />
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            component="span"
                            startIcon={<FileUploadIcon />}
                            disabled={!formik.values.mediaType}
                          >
                            Upload File
                          </Button>
                          {showMaximimumUploadSizeError && (
                            <Alert severity="error" sx={{ marginTop: 1 }}>
                              Maximum upload size reached. Please upload a file
                              smaller than 1 GB.
                            </Alert>
                          )}
                        </label>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <span>- or -</span>
                      </Stack>
                    </Grid>

                    <Grid item xs={5}>
                      <Stack direction="column" spacing={1}>
                        <TextField
                          type="text"
                          id="assetUrl"
                          name="assetUrl"
                          label="Type an asset url"
                          value={assetUrl}
                          onChange={(e) => {
                            setAssetUrl(e.target.value);
                            if (assets?.asset)
                              setAssets({ ...assets, asset: undefined });
                          }}
                          disabled={!!assets?.asset}
                        />
                        <i>Ex: https://www.mytaverse.com</i>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>

                <Divider />

                <Grid container spacing={1} direction="row">
                  {formik.values.mediaType?.startsWith('3d') &&
                    assets?.description3dImage && (
                      <Grid item xs={6} overflow="hidden">
                        <h5>Description Image (3D)</h5>
                        <img
                          src={
                            assets?.description3dImage
                              ? URL.createObjectURL(assets.description3dImage)
                              : ''
                          }
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            borderRadius: 5,
                          }}
                        />
                      </Grid>
                    )}

                  {formik.values.mediaType === PoiMediaType.Video &&
                    assets?.asset && (
                      <Grid item xs={6}>
                        <h5>Asset Preview</h5>
                        <video
                          width="100%"
                          controls
                          key={
                            assets?.asset
                              ? URL.createObjectURL(assets.asset)
                              : assetUrl
                          }
                          style={{ borderRadius: 4, overflow: 'hidden' }}
                        >
                          <source
                            src={
                              assets?.asset
                                ? URL.createObjectURL(assets.asset)
                                : assetUrl
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </Grid>
                    )}
                  {formik.values.mediaType == PoiMediaType.Image &&
                    assets?.asset && (
                      <Grid item xs={6}>
                        <h5>Asset Preview</h5>
                        <img
                          src={
                            assets?.asset
                              ? URL.createObjectURL(assets.asset)
                              : ''
                          }
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            borderRadius: 5,
                          }}
                        />
                      </Grid>
                    )}

                  {(formik.values.mediaType == PoiMediaType.File ||
                    formik.values.mediaType == PoiMediaType.Website) &&
                    assets?.asset && (
                      <Grid item xs={6}>
                        <h5>Asset Preview</h5>
                        <Link
                          target="_blank"
                          href={
                            assets?.asset
                              ? URL.createObjectURL(assets.asset)
                              : ''
                          }
                        >
                          File loaded
                        </Link>
                      </Grid>
                    )}

                  {assets?.displayImage && (
                    <Grid item xs={6} overflow="hidden">
                      <h5>Display Image Preview</h5>
                      <img
                        src={
                          assets?.displayImage
                            ? URL.createObjectURL(assets.displayImage)
                            : ''
                        }
                        style={{
                          width: '100%',
                          overflow: 'hidden',
                          borderRadius: 5,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  onClick={() => {
                    handleClose();
                    setAssets(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={formik.isSubmitting}>
                  {!formik.isSubmitting ? 'Update' : 'Updating...'}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}

      {isSendingFile && (
        <CustomizedSteppers
          activeStep={uploadStep}
          errorStep={errorStep}
          uploadProgress={uploadProgress}
        />
      )}
    </BoxDialog>
  );
}
